import { firstValueFrom } from 'rxjs';
import { Component, Input } from '@angular/core';
import { BookingService } from '../../services/booking.service';
import { IContact } from 'src/app/models/interfaces/IContact';

@Component({
  selector: 'app-button-contact',
  templateUrl: './button-contact.component.html',
  styleUrls: ['./button-contact.component.scss'],
})
export class ButtonContactComponent {
  @Input() size = 'normal';
  @Input() styleClass = 'btn-primary';
  @Input('data') contactData: IContact;

  constructor() {}

  async ngOnInit() {}
}
