import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, catchError, map, Observable, of } from 'rxjs';
import { IAccountDetails } from 'src/app/models/interfaces/IAccountDetails';
import { IArena } from 'src/app/models/interfaces/IArena';
import {
  IArenaPlan,
  IArenaPlanOutput,
} from 'src/app/models/interfaces/IArenaPlan';
import {
  IBookingByDate,
  IBookingByDateResponse,
  IBookingCalendar,
  IBookingCalendarFilter,
  IBookingWeeklyResponse,
} from 'src/app/models/interfaces/IBookingCalendarFilter';
import { IBookingConfirmationCustomer } from 'src/app/models/interfaces/IBookingConfirmationCustomer';
import { IBookingConfirmationInput } from 'src/app/models/interfaces/IBookingConfirmationInput';
import {
  IBookingConfirmationInOut,
  IBookingConfirmationOut,
} from 'src/app/models/interfaces/IBookingConfirmationOut';
import {
  IBookingDetails,
  IBookingPaymentInfoResponse,
} from 'src/app/models/interfaces/IBookingDetails';
import { IBookingOutput } from 'src/app/models/interfaces/IBookingOutput';
import { IBookingPaymentDetails } from 'src/app/models/interfaces/IBookingPaymentDetails';
import { IContact } from 'src/app/models/interfaces/IContact';
import { IDuration } from 'src/app/models/interfaces/IDuration';
import { IFaq } from 'src/app/models/interfaces/IFaq';
import { ILoginCode } from 'src/app/models/interfaces/ILoginCode';
import { IMyBooking } from 'src/app/models/interfaces/IMyBooking';
import { IPaymentStatus } from 'src/app/models/interfaces/IPaymentStatus';
import {
  IRegister,
  IRegisterValidation,
} from 'src/app/models/interfaces/IRegister';
import { IResponseDefault } from 'src/app/models/interfaces/IResponseDefault';
import { ISportArena } from 'src/app/models/interfaces/ISportArena';
import { ITerm } from 'src/app/models/interfaces/ITerm';
import {
  IValidateCPF,
  IValidatePhone,
} from 'src/app/models/interfaces/IValidate';
import {
  ICouponResponse,
  IValidateCoupon,
} from 'src/app/models/interfaces/IValidateCoupon';

import { DestinationService } from '../enums/DestinationService.enum';
import { FormOfPayment } from '../enums/FormOfPayment';
import { BaseService } from './base.service';
import { BookingPaymentStatus } from '../enums/BookingPaymentStatus.enum';

@Injectable({
  providedIn: 'root',
})
export class BookingService extends BaseService {
  constructor(private http: HttpClient) {
    super();
  }

  breadcrumb$ = new BehaviorSubject<string[]>([]);
  title$ = new BehaviorSubject<string>('');
  showFaq$ = new BehaviorSubject<boolean>(false);
  showFooterBread$ = new BehaviorSubject<boolean>(true);
  loader$ = new BehaviorSubject<boolean>(false);

  toggleLoader(bool = false) {
    this.loader$.next(bool);
  }

  getYears(): number[] {
    let initial = new Date().getFullYear();
    let arrYears = [];
    for (let i = 0; i < 100; i++) {
      arrYears.push(initial - i);
    }
    return arrYears;
  }

  getMonths(): { description: string; value: number }[] {
    const arrMonths = [
      { value: 1, description: 'Janeiro' },
      { value: 2, description: 'Fevereiro' },
      { value: 3, description: 'Março' },
      { value: 4, description: 'Abril' },
      { value: 5, description: 'Maio' },
      { value: 6, description: 'Junho' },
      { value: 7, description: 'Julho' },
      { value: 8, description: 'Agosto' },
      { value: 9, description: 'Setembro' },
      { value: 10, description: 'Outubro' },
      { value: 11, description: 'Novembro' },
      { value: 12, description: 'Dezembro' },
    ];
    return arrMonths;
  }

  getFaq(): Observable<IFaq[]> {
    return this.http
      .get<IResponseDefault<IFaq>>(`${this.bookingAPI}GetFaq`, {
        headers: new HttpHeaders().set('x-skip-autorizacao', '1'),
      })
      .pipe(
        map((data) => {
          return data.list.map((item) => {
            item.expanded = false;
            return item;
          });
        }),
        catchError(this.serviceError.bind(this) as any)
      );
  }

  getArenas(): Observable<IArena[]> {
    return this.http
      .get<IResponseDefault<IArena>>(`${this.bookingAPI}GetArenas`, {
        headers: new HttpHeaders().set('x-skip-autorizacao', '1'),
      })
      .pipe(
        map((data) => {
          return data.list.map((item) => {
            item.images = JSON.parse(item.images as unknown as string);
            item.benefits = JSON.parse(item.benefits as unknown as string);
            return item;
          });
        }),
        catchError(this.serviceError.bind(this) as any)
      );
  }

  getSportsArenas(): Observable<ISportArena[]> {
    return this.http
      .get<IResponseDefault<ISportArena>>(`${this.bookingAPI}GetSportsArenas`, {
        headers: new HttpHeaders().set('x-skip-autorizacao', '1'),
      })
      .pipe(
        map((data) => {
          return data.list.map((item) => {
            item.arenas = JSON.parse(item.arenas as unknown as string);
            return item;
          });
        }),
        catchError(this.serviceError.bind(this) as any)
      );
  }

  getArenaPlans(sportModalityId: string): Observable<IArenaPlanOutput[]> {
    return this.http
      .get<IResponseDefault<IArenaPlanOutput>>(
        `${this.bookingAPI}GetArenasPlans/${sportModalityId}`,
        {
          headers: new HttpHeaders().set('x-skip-autorizacao', '1'),
        }
      )
      .pipe(
        map((data) => {
          return data.list.map((item) => {
            item.plans = JSON.parse(item.plans as any as string);
            return item;
          });
        }),
        catchError(this.serviceError.bind(this) as any)
      );
  }

  getSingleBookingsCalendar(
    filter: IBookingCalendarFilter
  ): Observable<IBookingCalendar[]> {
    return this.http
      .post<IResponseDefault<IBookingCalendar[]>>(
        `${this.bookingAPI}GetSingleBookingsCalendar`,
        filter,
        {
          headers: new HttpHeaders().set('x-skip-autorizacao', '1'),
        }
      )
      .pipe(
        map((data) => {
          return data.list;
        }),
        catchError(this.serviceError.bind(this) as any)
      );
  }

  getSingleBookingsByDate(
    filter: IBookingCalendarFilter
  ): Observable<IBookingByDateResponse> {
    return this.http
      .post<IResponseDefault<IBookingByDateResponse>>(
        `${this.bookingAPI}GetSingleBookingsByDate`,
        filter,
        {
          headers: new HttpHeaders().set('x-skip-autorizacao', '1'),
        }
      )
      .pipe(catchError(this.serviceError.bind(this) as any));
  }

  getWeeklyBookings(
    filter: IBookingCalendarFilter
  ): Observable<IBookingWeeklyResponse> {
    return this.http
      .post<IResponseDefault<IBookingWeeklyResponse>>(
        `${this.bookingAPI}GetWeeklyBookings`,
        filter,
        {
          headers: new HttpHeaders().set('x-skip-autorizacao', '1'),
        }
      )
      .pipe(catchError(this.serviceError.bind(this) as any));
  }

  getContact(): Observable<IContact> {
    return this.http
      .get<IResponseDefault<IContact>>(`${this.bookingAPI}GetContact`, {
        headers: new HttpHeaders().set('x-skip-autorizacao', '1'),
      })
      .pipe(catchError(this.serviceError.bind(this) as any));
  }

  getDurations(sportModalityId: string): Observable<IDuration[]> {
    return this.http
      .get<IResponseDefault<IDuration[]>>(
        `${this.bookingAPI}GetDurations/${sportModalityId}`,
        {
          headers: new HttpHeaders().set('x-skip-autorizacao', '1'),
        }
      )
      .pipe(
        map((data) => {
          return data.list;
        }),
        catchError(this.serviceError.bind(this) as any)
      );
  }

  getListTerms(): Observable<ITerm[]> {
    return this.http
      .get<IResponseDefault<ITerm[]>>(`${this.bookingAPI}GetListTerms`, {
        headers: new HttpHeaders().set('x-skip-autorizacao', '1'),
      })
      .pipe(
        map((data) => {
          return data.list;
        }),
        catchError(this.serviceError.bind(this) as any)
      );
  }

  postGenerateLoginCode(
    phone: string,
    destination: DestinationService
  ): Observable<ILoginCode[]> {
    return this.http
      .post<IResponseDefault<ILoginCode[]>>(
        `${this.bookingAPI}GenerateLoginCode`,
        {
          phone,
          destinationService: destination,
        },
        {
          headers: new HttpHeaders().set('x-skip-autorizacao', '1'),
        }
      )
      .pipe(
        map((data) => {
          return data.list;
        }),
        catchError(this.serviceError.bind(this) as any)
      );
  }

  postGenerateCreateAccountCode(
    phone: string,
    destination: DestinationService
  ): Observable<ILoginCode[]> {
    return this.http
      .post<IResponseDefault<ILoginCode[]>>(
        `${this.bookingAPI}GenerateCreateAccountCode`,
        {
          phone,
          destinationService: destination,
        },
        {
          headers: new HttpHeaders().set('x-skip-autorizacao', '1'),
        }
      )
      .pipe(
        map((data) => {
          return data.list;
        }),
        catchError(this.serviceError.bind(this) as any)
      );
  }

  validateAccount(
    register: IRegister,
    isEdit = false
  ): Observable<IRegisterValidation> {
    return this.http
      .post<IRegisterValidation>(
        `${this.bookingAPI}ValidateAccount`,
        register,
        {
          headers: !isEdit
            ? new HttpHeaders().set('x-skip-autorizacao', '1')
            : null,
        }
      )
      .pipe(catchError(this.serviceError.bind(this) as any));
  }

  createAccount(register: IRegister): Observable<IRegisterValidation> {
    return this.http
      .post<IRegisterValidation>(`${this.bookingAPI}CreateAccount`, register, {
        headers: new HttpHeaders().set('x-skip-autorizacao', '1'),
      })
      .pipe(catchError(this.serviceError.bind(this) as any));
  }

  postBookingConfirmation(
    booking: IBookingConfirmationInput
  ): Observable<IBookingConfirmationOut> {
    return this.http
      .post<IBookingConfirmationOut>(
        `${this.bookingAPI}GetBookingConfirmation`,
        booking,
        {
          headers: new HttpHeaders().set('x-skip-autorizacao', '1'),
        }
      )
      .pipe(catchError(this.serviceError.bind(this) as any));
  }

  getBookingConfirmationCustomer(): Observable<IBookingConfirmationCustomer> {
    return this.http
      .get<IBookingConfirmationCustomer>(
        `${this.bookingAPI}GetBookingConfirmationCustomer`
      )
      .pipe(catchError(this.serviceError.bind(this) as any));
  }

  postValidateCoupon(data: IValidateCoupon): Observable<ICouponResponse> {
    return this.http
      .post<ICouponResponse>(`${this.bookingAPI}ValidateCoupon`, data)
      .pipe(catchError(this.serviceError.bind(this) as any));
  }

  getValidatePhone(phone: string): Observable<IValidatePhone> {
    return this.http
      .get<IValidatePhone>(`${this.bookingAPI}ValidatePhone/${phone}`, {
        headers: new HttpHeaders().set('x-skip-autorizacao', '1'),
      })
      .pipe(catchError(this.serviceError.bind(this) as any));
  }

  getValidateCPF(cpf: string): Observable<IValidateCPF> {
    return this.http
      .get<IValidateCPF>(`${this.bookingAPI}ValidateCPF/${cpf}`)
      .pipe(catchError(this.serviceError.bind(this) as any));
  }

  createBooking(
    data: Partial<IBookingConfirmationInOut>
  ): Observable<IBookingOutput> {
    return this.http
      .post<IBookingOutput>(`${this.bookingAPI}CreateBooking`, data)
      .pipe(catchError(this.serviceError.bind(this) as any));
  }

  getPaymentStatus(bookingId: string): Observable<IPaymentStatus> {
    return this.http
      .get<IPaymentStatus>(`${this.bookingAPI}GetPaymentStatus/${bookingId}`)
      .pipe(catchError(this.serviceError.bind(this) as any));
  }

  getMyBookings(next = false): Observable<IResponseDefault<IMyBooking>> {
    return this.http
      .get<IMyBooking>(`${this.bookingAPI}GetMyBookings/${next}`)
      .pipe(catchError(this.serviceError.bind(this) as any));
  }

  getBookingDetails(bookingId: string): Observable<IBookingDetails> {
    // return of({
    //   arenaName: 'Appito Arena Vila Leopoldina',
    //   planDesc: 'Reserva avulsa',
    //   sportDesc: 'Futebol Society',
    //   timeSpaceDesc: '18:30 - 20:00 | Quadra Q1',
    //   dateDesc: 'Sábado, 07/out',
    //   paymentStatus: BookingPaymentStatus.Pendente,
    //   paymentStatusDesc: 'Aguardando pagamento: Sinal da reserva',
    //   startOfPlan: '',
    //   renewObs: '',
    //   showPixButton: true,
    //   showBoletoButton: false,
    //   showChargeButton: true,
    //   showWhatsAppButton: false,
    //   paymentObs:
    //     'O pagamento do sinal garante a reserva de seu horário e não é reembolsável. Após o vencimento, a pré-reserva será cancelada.',
    //   paymentLabel: 'Pague até',
    //   dueDate: '06/07/22',
    //   value: 'R$ 165,00',
    //   mapURL: 'https://maps.app.goo.gl/EgMujMAcTVhFgjDH8',
    //   urlContact:
    //     'https://api.whatsapp.com/send/?phone=551146731040&text&type=phone_number&app_absent=0',
    //   downloadAPPTitle1: 'Para tornar a experiência completa',
    //   downloadAPPTitle2: 'Baixe agora o app Appito',
    //   downloadAPPText:
    //     'Tudo para a organização de seu jogo. Convide, sorteie, controle pagamentos, registre estatísticas e avalie.',
    //   downloadAPPURLiOS:
    //     'https://itunes.apple.com/br/app/appito/id1054372715?mt=8',
    //   downloadAPPURLAndroid:
    //     'https://play.google.com/store/apps/details?id=com.apitador.app',
    //   address: 'Av. Eng. Roberto Zuccolo, 214',
    //   imageMapURL: 'https://tinyurl.com/arena-leopoldina-reserva',
    //   more: false,
    //   paymentURL: null,
    //   list: [],
    //   message: '',
    //   error: false,
    // });
    return this.http
      .get<IBookingDetails>(`${this.bookingAPI}GetBookingDetails/${bookingId}`)
      .pipe(catchError(this.serviceError.bind(this) as any));
  }

  getBookingPaymentHistory(
    bookingId: string,
    page = 0
  ): Observable<IBookingPaymentInfoResponse> {
    return this.http
      .get<IBookingPaymentInfoResponse>(
        `${this.bookingAPI}GetBookingPaymentHistory/${bookingId}/${page}`
      )
      .pipe(catchError(this.serviceError.bind(this) as any));
  }

  getBookingPaymentDetails(
    bookingId: string,
    formOfPayment: FormOfPayment
  ): Observable<IBookingPaymentDetails> {
    return this.http
      .get<IBookingPaymentDetails>(
        `${this.bookingAPI}GetBookingPaymentDetails/${bookingId}/${formOfPayment}`
      )
      .pipe(catchError(this.serviceError.bind(this) as any));
  }

  getAccountDetails(): Observable<IAccountDetails> {
    return this.http
      .get<IAccountDetails>(`${this.bookingAPI}GetAccountDetails`)
      .pipe(catchError(this.serviceError.bind(this) as any));
  }

  getAccountDetailsForEditing(): Observable<IRegister> {
    return this.http
      .get<IRegister>(`${this.bookingAPI}GetAccountDetailsForEditing`)
      .pipe(catchError(this.serviceError.bind(this) as any));
  }

  saveAccountEdit(data: IRegister): Observable<IRegisterValidation> {
    return this.http
      .put<IRegisterValidation>(`${this.bookingAPI}SaveAccount`, data)
      .pipe(catchError(this.serviceError.bind(this) as any));
  }
}
