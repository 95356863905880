import { debounceTime, distinctUntilChanged, firstValueFrom } from 'rxjs';
import { Component } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { IFaq } from 'src/app/models/interfaces/IFaq';
import { BookingService } from 'src/app/shared/services/booking.service';
import {
  animate,
  AUTO_STYLE,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';
import { ITerm } from 'src/app/models/interfaces/ITerm';
import { SecurityService } from 'src/app/shared/services/security.service';
import { CacheService } from 'src/app/shared/services/cache.service';
import { IContact } from 'src/app/models/interfaces/IContact';
import { LoginService } from 'src/app/shared/services/login.service';

@Component({
  selector: 'app-main-template',
  templateUrl: './main-template.component.html',
  styleUrls: ['./main-template.component.scss'],
  animations: [
    trigger('collapse', [
      state('false', style({ height: AUTO_STYLE, display: AUTO_STYLE })),
      state('true', style({ height: '0', display: 'none' })),
      transition('false => true', animate(300 + 'ms ease-in')),
      transition('true => false', animate(300 + 'ms ease-out')),
    ]),
  ],
})
export class MainTemplateComponent {
  title!: string;
  showFaq = false;
  showFooterBread = true;
  faq: IFaq[] = [];
  breadcrumb!: string[];
  listTerms: ITerm[];
  routeLogin: string;
  isLogged: boolean = null;
  contactData: IContact;
  loader = false;

  constructor(
    private route: ActivatedRoute,
    private bookingService: BookingService,
    private loginService: LoginService,
    private router: Router,
    private securityService: SecurityService,
    private cacheService: CacheService
  ) {}

  async ngOnInit() {
    this.bookingService.breadcrumb$.subscribe((data) => {
      this.breadcrumb = data;
    });

    this.bookingService.showFooterBread$.subscribe((data) => {
      this.showFooterBread = data;
    });

    this.bookingService.title$.subscribe((data) => {
      this.title = data;
    });

    this.bookingService.loader$.subscribe((data) => {
      this.loader = data;
    });

    this.loginService.loader$.subscribe((data) => {
      this.loader = data;
    });

    this.bookingService.showFaq$.subscribe((data) => {
      this.showFaq = data;
      if (this.showFaq) {
        this.getFaq();
      }
    });

    this.isLogged = this.cacheService.checkUserAuth();

    this.contactData = await firstValueFrom(this.bookingService.getContact());

    this.listTerms = await firstValueFrom(this.bookingService.getListTerms());

    this.routeLogin = `/login?returnUrl=${location.pathname}`;

    this.securityService.isLoggedIn.subscribe((data) => {
      this.isLogged = data;
    });
  }

  navigateToLogin() {
    const queryParams: Params = {
      returnUrl: location.pathname.replace(/\//, ''),
    };
    this.router.navigate(['/login'], { queryParams });
  }

  getFaq() {
    if (this.faq.length > 0) {
      return;
    }
    this.bookingService.getFaq().subscribe((data) => {
      this.faq = data.map((item) => {
        item.answer = item.answer.replaceAll('\r\n', '<br/>');
        return item;
      });
    });
  }

  collapse(faqItem: IFaq) {
    faqItem.expanded = !faqItem.expanded;
    this.faq
      .filter((item) => item.question !== faqItem.question)
      .forEach((item) => (item.expanded = false));
  }

  logout() {
    this.securityService.logout();
  }
}
