import { environment } from 'src/environments/environment';

export abstract class BaseService {
  // TODO: PRODUCTION CONFIGURATION HERE
  protected bookingAPI: string = environment.bookingAPI;
  protected utilAPI: string = environment.utilAPI;
  protected loginAPI: string = environment.loginAPI;

  protected extractData(response: any) {
    return response.data || {};
  }

  protected config() {
    return {
      headers: {
        'Content-Type': 'application/json',
        'Content-Encoding': 'br',
      },
    };
  }

  protected serviceError(response: Response | any): any {
    let mensagemErro = '';

    if (response.error != null) {
      if (response.error) {
        mensagemErro = response.error;
      }

      // if (response.error.errors) {
      //   mensagemErro = response.error.errors.join(',');
      // }
    }
    if (response.status === 0) {
      mensagemErro = 'Erro ao se conectar à base de dados!';
    }

    // if (response.status === 401) {
    //   sessionStorage.clear();
    //   localStorage.clear();
    // }

    if (response.status === 405) {
      mensagemErro = 'Erro interno no servidor!';
    }

    if (response.status === 500) {
      mensagemErro = 'Erro interno no servidor!';
    }
    if (response.status === 400) {
      mensagemErro = response;
    }

    throw mensagemErro;
  }
}
