import { EventEmitter, Injectable } from '@angular/core';

import { IUserAuth, IUserAuthToken } from 'src/app/models/interfaces/IUserAuth';
import {
  USER_AUTHENTICATION_BOOKING,
  USER_AUTHENTICATION_INFO,
} from '../constants';
import { storage } from '../utils/storage';
import { IBookingConfirmationOut } from 'src/app/models/interfaces/IBookingConfirmationOut';
import { IBookingConfirmationInput } from 'src/app/models/interfaces/IBookingConfirmationInput';

interface dateCache {
  expiration: string;
  date: string;
}
@Injectable({
  providedIn: 'root',
})
export class CacheService {
  public userInfoChanged = new EventEmitter<IUserAuthToken>();

  constructor() {}

  /**
   * Método que retorna os dados do usuario salvos no cache
   */
  getLoggedUserInfo(): IUserAuthToken {
    let result = JSON.parse(
      storage.getEncripted(USER_AUTHENTICATION_INFO) as string
    );
    return result;
  }

  /**
   * Método que insere os dados do usuario do cache
   */
  setLoggedUserInfo(userAuthInfo: IUserAuthToken): Promise<IUserAuthToken> {
    this.removeUserAuth();

    storage.setEncripted(
      USER_AUTHENTICATION_INFO,
      JSON.stringify(userAuthInfo)
    );

    this.userInfoChanged.emit(userAuthInfo);
    return Promise.resolve(userAuthInfo);
  }

  /**
   * Método que remove os dados do usuario do cache
   */
  removeUserAuth() {
    storage.remove(USER_AUTHENTICATION_INFO);
  }

  /**
   * Método que verifica se o usuario está autenticado
   */
  checkUserAuth() {
    const userInfo = this.getLoggedUserInfo();
    if (userInfo === null) {
      return false;
    }

    const now = new Date();
    const expirationDate = new Date(userInfo.expiration);
    if (expirationDate.valueOf() - now.getTimezoneOffset() < now.valueOf()) {
      this.removeUserAuth();
      return false;
    }

    return true;
  }

  /**
   * Método que retorna se o usuario está autenticado
   */
  isUserAuthenticated(): boolean {
    this.checkUserAuth();
    const userInfo = this.getLoggedUserInfo();
    return userInfo != null;
  }

  setUserBooking(
    bookingDetails: IBookingConfirmationOut,
    booking: IBookingConfirmationInput
  ) {
    storage.setEncripted(
      USER_AUTHENTICATION_BOOKING,
      JSON.stringify({ ...booking, ...bookingDetails })
    );
  }

  removeUserBooking() {
    storage.remove(USER_AUTHENTICATION_BOOKING);
  }
}
