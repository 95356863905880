<section class="container">
  <div class="jumbotron my-5">
    <h1 class="display-4">Acesso Negado </h1>
    <p class="lead">Você não possui acesso a essa página. </p>
    <hr class="my-4" />
    <p>Clique no link abaixo para voltar para a página inicial</p>
    <a class="btn btn-outline-secondary btn-lg" routerLink="../" role="button"
      >Voltar</a
    >
  </div>
</section>
