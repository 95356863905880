<a
  [href]="contactData.urlContact"
  target="_blank"
  [class]="styleClass"
  [class.btn-large]="size === 'large'"
  *ngIf="contactData"
>
  <img src="assets/images/logo-whatsapp.svg" alt="Whatsapp" />{{
    contactData.textButton
  }}
</a>
