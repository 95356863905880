import { RouterModule, Routes } from '@angular/router';

import { ErrorsComponent } from './core/errors/errors.component';
import { ForbiddenComponent } from './core/forbidden/forbidden.component';
import { MainTemplateComponent } from './shared/components/main-template/main-template.component';
import { NgModule } from '@angular/core';

const routes: Routes = [
  {
    path: '',
    loadChildren: () =>
      import('./modules/start/start.module').then((m) => m.StartModule),
  },

  {
    path: 'reserva',
    loadChildren: () =>
      import('./modules/booking/booking.module').then((m) => m.BookingModule),
  },

  {
    path: 'convite',
    loadChildren: () =>
      import('./modules/invite/invite.module').then((m) => m.InviteModule),
  },

  {
    path: 'login',
    loadChildren: () =>
      import('./modules/login/login.module').then((m) => m.LoginModule),
  },
  {
    path: 'videos-antigos/:id',
    loadChildren: () =>
      import('./modules/video/video.module').then((m) => m.VideoModule),
  },
  {
    path: 'videos',
    loadChildren: () =>
      import('./modules/videos/videos.module').then((m) => m.VideosModule),
  },
  {
    path: 'minha-conta',
    loadChildren: () =>
      import('./modules/my-account/my-account.module').then(
        (m) => m.MyAccountModule
      ),
  },
  {
    path: '404',
    component: ErrorsComponent,
  },
  {
    path: '403',
    component: ForbiddenComponent,
  },
  {
    path: '**',
    component: ErrorsComponent,
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
