import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';
import { distinctUntilChanged, map } from 'rxjs/operators';
import { CacheService } from 'src/app/shared/services/cache.service';
import { IUserAuthToken } from 'src/app/models/interfaces/IUserAuth';

@Injectable({
  providedIn: 'root',
})
export class SecurityService {
  private loggedIn = new BehaviorSubject<boolean>(false);
  private userAuth: IUserAuthToken;

  /**
   * Método que verifica se o usuário está logado
   */
  get isLoggedIn(): Observable<boolean> {
    if (this.cacheService.checkUserAuth()) {
      this.loggedIn.next(true);
    }
    return this.loggedIn.asObservable().pipe(distinctUntilChanged());
  }

  constructor(
    private router: Router,
    private cacheService: CacheService
  ) {
    this.userAuth = this.cacheService.getLoggedUserInfo();
  }

  /**
   * Método que faz o login, setando o Observable como true e direcionando para a rota principal
   */
  login(returnUrl: string): any {
    this.loggedIn.next(true);
    this.router.navigate([returnUrl]);
  }

  /**
   * Método que faz o logout, setando o Observable como false e direcioando para a rota de login
   */
  logout(): any {
    this.loggedIn.next(false);
    this.router.navigate(['/']);
    this.cacheService.removeUserAuth();
    this.cacheService.removeUserBooking();
  }
}
