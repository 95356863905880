import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { ErrorsComponent } from './errors/errors.component';
import { ForbiddenComponent } from './forbidden/forbidden.component';
import { MainTemplateComponent } from './main-template/main-template.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { SharedModule } from '../shared/shared.module';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { MatProgressBarModule } from '@angular/material/progress-bar';
@NgModule({
  declarations: [ErrorsComponent, ForbiddenComponent, MainTemplateComponent],
  imports: [
    CommonModule,
    RouterModule,
    BrowserAnimationsModule,
    SharedModule,
    BrowserAnimationsModule,
    BsDropdownModule,
    MatProgressBarModule,
  ],
  exports: [MainTemplateComponent],
})
export class CoreModule {}
