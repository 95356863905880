import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ButtonContactComponent } from './components/button-contact/button-contact.component';
import { AutoFocusDirective } from './directives/auto-focus.directive';

@NgModule({
  declarations: [ButtonContactComponent, AutoFocusDirective],
  imports: [CommonModule],
  exports: [ButtonContactComponent],
})
export class SharedModule {}
