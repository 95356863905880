import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, catchError, map, Observable } from 'rxjs';
import { IUserAuth } from 'src/app/models/interfaces/IUserAuth';

import { BaseService } from './base.service';
import { CacheService } from './cache.service';

@Injectable({
  providedIn: 'root',
})
export class LoginService extends BaseService {
  loader$ = new BehaviorSubject<boolean>(false);

  constructor(private http: HttpClient, private cacheService: CacheService) {
    super();
  }

  postGenerateToken(
    phone: string,
    code: string,
    isAnonymous = false
  ): Observable<IUserAuth> {
    return this.http
      .post<any>(
        `${this.loginAPI}Token`,
        {
          phone,
          code,
          isAnonymous,
        },
        {
          headers: new HttpHeaders().set('x-skip-autorizacao', '1'),
        }
      )
      .pipe(
        map((data) => {
          if (data && data.token) {
            this.cacheService.setLoggedUserInfo(data);
          }
          return data;
        }),
        catchError(this.serviceError.bind(this) as any)
      );
  }
}
