// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

// const API_URL = 'http://20.236.195.182/';
const API_URL = 'https://manager-api-qa.appito.com/';

export const environment = {
  production: false,
  bookingAPI: `${API_URL}api/onlinebooking/`,
  utilAPI: `${API_URL}api/utils/`,
  loginAPI: `${API_URL}api/loginauth/`,
};
// export const environment = {
//   production: false,
//   bookingAPI: `${API_URL}ms-appitobookingonline/OnlineBooking/`,
//   utilAPI: `${API_URL}ms-appitoutils/UtilsMicroservice/`,
//   loginAPI: `${API_URL}ms-appitoauthlogin/LoginAuth/`,
// };
/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
